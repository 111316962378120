import React from "react";
import { marked } from "marked";
import { Button } from "./ui/button";
import { Card } from "./ui/card";
import { ChevronRight } from "lucide-react";

const calculateTotalMarks = (taskEvaluations) => {
  return taskEvaluations?.reduce((total, task) => total + (task.markAwarded || 0), 0) || 0;
};

const calculateQ6TotalMarks = (feedback) => {
  // Sum technical implementation marks (6 marks possible)
  const technicalMarks = feedback.technicalImplementation.criteria.reduce((total, criterion) => total + (criterion.markAwarded || 0), 0);

  // Sum levels-based assessment marks (9 marks possible)
  const levelsMarks =
    (feedback.levelsBasedAssessment.solutionDesign.levelAwarded || 0) +
    (feedback.levelsBasedAssessment.goodProgrammingPractice.levelAwarded || 0) +
    (feedback.levelsBasedAssessment.functionality.levelAwarded || 0);

  return technicalMarks + levelsMarks;
};

const FeedbackDisplay = ({
  feedback,
  calculatedTotalMark,
  finalTotalMark,
  questionId,
  totalQuestions,
  currentQuestionIndex,
  onNextQuestion,
  recheck,
}) => {
  if (!feedback) return null;

  const hasMoreQuestions = currentQuestionIndex < totalQuestions - 1;

  // Q6 specific feedback sections
  const renderQ6Feedback = (feedback) => {
    return (
      <div className="">
        <h3 className="text-xl font-semibold mb-4">Feedback:</h3>

        {/* Technical Implementation Section */}
        <div className="mb-6">
          <h4 className="font-semibold text-gray-700 mb-3">Technical Implementation (6 marks)</h4>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">Task</th>
                <th className="border border-gray-300 p-2">Description</th>
                <th className="border border-gray-300 p-2 w-20">Mark</th>
                <th className="border border-gray-300 p-2">Evidence</th>
              </tr>
            </thead>
            <tbody>
              {feedback.technicalImplementation.criteria.map((criterion, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 p-2 w-[100px]">{criterion.criterionNumber}</td>
                  <td className="border border-gray-300 p-2">{criterion.description}</td>
                  <td className="border border-gray-300 p-2 text-center">{criterion.markAwarded}/1</td>
                  <td className="border border-gray-300 p-2">{criterion.evidence}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Levels-Based Assessment Section */}
        <div className="mb-6">
          <h4 className="font-semibold text-gray-700 mb-3">Levels-Based Assessment (9 marks)</h4>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">Category</th>
                <th className="border border-gray-300 p-2 w-20">Level</th>
                <th className="border border-gray-300 p-2">Evidence</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">Solution Design</td>
                <td className="border border-gray-300 p-2 text-center">{feedback.levelsBasedAssessment.solutionDesign.levelAwarded}/3</td>
                <td className="border border-gray-300 p-2">{feedback.levelsBasedAssessment.solutionDesign.evidence.join("; ")}</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Good Programming Practice</td>
                <td className="border border-gray-300 p-2 text-center">{feedback.levelsBasedAssessment.goodProgrammingPractice.levelAwarded}/3</td>
                <td className="border border-gray-300 p-2">{feedback.levelsBasedAssessment.goodProgrammingPractice.evidence.join("; ")}</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">Functionality</td>
                <td className="border border-gray-300 p-2 text-center">{feedback.levelsBasedAssessment.functionality.levelAwarded}/3</td>
                <td className="border border-gray-300 p-2">{feedback.levelsBasedAssessment.functionality.evidence.join("; ")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Total Mark */}
        <div className="mt-4 p-3 bg-green-50 rounded-lg inline-block">
          <strong className="text-xl text-green-800">
            Total Mark: {calculatedTotalMark} / {finalTotalMark}
          </strong>
        </div>

        {/* Overall comment */}
        {feedback.overallComment && (
          <div className="mt-4 bg-gray-50 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Overall Comment:</h4>
            <p className="text-gray-700 italic">{feedback.overallComment}</p>
          </div>
        )}

        {/* Suggestions section */}
        {feedback.suggestions && feedback.suggestions.length > 0 && (
          <div className="mt-6 bg-blue-50 p-4 rounded-lg">
            <h4 className="font-semibold text-blue-800 mb-2">How to Improve:</h4>
            <ul className="list-disc list-inside space-y-1">
              {feedback.suggestions.map((suggestion, index) => (
                <li key={index} className="text-blue-700">
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
        )}    
      </div>
    );
  };

  return (
    <div className="space-y-6 mt-8">
      <div className="feedback-content">
        {questionId === "q6" ? (
          renderQ6Feedback(feedback)
        ) : (
          // Standard feedback display for all other questions
          <div>
            <h3 className="text-xl font-semibold mb-4">Feedback:</h3>
            <table className="w-full border-collapse border border-gray-300 mt-4">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-2">Task</th>
                  <th className="border border-gray-300 p-2">Mark</th>
                  <th className="border border-gray-300 p-2">Feedback</th>
                </tr>
              </thead>
              <tbody>
                {feedback.taskEvaluations?.map((task, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 p-2 w-[100px]">Task {task.taskNumber}</td>
                    <td className="border border-gray-300 p-2 text-center">{task.markAwarded}</td>
                    <td className="border border-gray-300 p-2">{task.feedback}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-4 p-3 bg-blue-50 rounded-lg inline-block">
              <strong className="text-xl text-blue-800">
                Total Mark: {calculatedTotalMark} / {finalTotalMark}
              </strong>
            </div>

            {/* Suggestions section */}
            {feedback.suggestions && feedback.suggestions.length > 0 && (
              <div className="mt-6 bg-blue-50 p-4 rounded-lg">
                <h4 className="font-semibold text-blue-800 mb-2">How to Improve:</h4>
                <ul className="list-disc list-inside space-y-1">
                  {feedback.suggestions.map((suggestion, index) => (
                    <li key={index} className="text-blue-700">
                      {suggestion}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Overall comment */}
            {feedback.overallComment && (
              <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Overall Comment:</h4>
                <p className="text-gray-700 italic">{feedback.overallComment}</p>
              </div>
            )}
          </div>
        )}
      </div>
      {recheck[currentQuestionIndex] && (
        <div className="mt-8 p-6 bg-blue-50 border border-blue-200 rounded-lg">
          <h3 className="text-xl font-semibold text-blue-800 mb-4">Recheck Feedback </h3>
          <p className="text-blue-700 mb-4">{recheck[currentQuestionIndex]?.u_feedback}</p>
        </div>
      )}
      {/* Next Question button */}
      {hasMoreQuestions && (
        <Card className="bg-blue-50 p-4 mt-6 border-blue-200">
          <div className="flex flex-col items-center space-y-4">
            <h3 className="text-lg font-semibold text-blue-800">Question {currentQuestionIndex + 1} Completed!</h3>
            <p className="text-blue-600">Ready to attempt Question {currentQuestionIndex + 2}?</p>
            <Button onClick={onNextQuestion} className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg flex items-center space-x-2">
              <span>Next Question</span>
              <ChevronRight className="h-5 w-5" />
            </Button>
            <p className="text-sm text-blue-600">{totalQuestions - currentQuestionIndex - 1} questions remaining</p>
          </div>
        </Card>
      )}
    </div>
  );
};

export default FeedbackDisplay;
