import React, { useEffect, useState, useRef } from "react";
import questions from "../data/questions";
import CodeEditor from "./CodeEditor";
import FeedbackDisplay from "./FeedbackDisplay";
import LoadingOverlay from "./LoadingOverlay";
import { evaluateCode, prepareSubmission } from "../services/openaiService";
import { Button } from "./ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Alert, AlertDescription } from "./ui/alert";
import { AlertCircle, CloudCog } from "lucide-react";
import temperatureTable from "../assets/temperature_table.png";
import { jsPDF } from "jspdf";
import RecheckSidePanel from "./widgets/RecheckDialog";
import axios from "axios";

const StudentAssessment = () => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading status

  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [studentName, setStudentName] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [savefeedback, setSaveFeedback] = useState([]);
  const [finalTotalMark, setFinalTotalMark] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editedCode, setEditedCode] = useState("");
  const [submittedPrompt, setSubmittedPrompt] = useState("");
  const [isEvaluating, setIsEvaluating] = useState(false);
  const [evaluationError, setEvaluationError] = useState(null);
  const [isCodeEdited, setIsCodeEdited] = useState(false);
  const totalQuestions = selectedQuestions.length;
  const [isResultsSent, setIsResultsSent] = useState(false);
  const [nameError, setNameError] = useState("");
  const errorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState("");
  const [obtainedMarks, setObtainedMarks] = useState(0);
  const [recheck, setRecheck] = useState([]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    console.log("Email in param : - ", email);
    if (email) {
      setTeacherEmail(email);
    }

    const selected = questions.filter((q) => params.get(q.id) === "true").map((q) => ({ ...q, isEdited: false }));
    setSelectedQuestions(selected);

    if (selected.length > 0) {
      setEditedCode(selected[0].initialCode);
    }
    console.log("selected in param : - ", selected);
  }, []);

  const extractSubmittedCode = (taskBlock, taskType) => {
    // Split the task block into lines
    const lines = taskBlock.split("\n");

    for (let line of lines) {
      line = line.trim();

      // For uncommenting tasks, return the first uncommented line
      if (taskType === "Multiple-Choice Uncommenting" && !line.startsWith("#") && line !== "") {
        return line;
      }

      // For code completion tasks, return the first line with code after the template
      if (taskType === "Code Completion" && line && !line.startsWith("#")) {
        return line;
      }
    }

    return "NO CHANGE"; // If no submitted code found, assume no changes made
  };

  const parseStudentSubmission = (code) => {
    const tasks = [];
    const taskBlocks = code.split("# =====>");

    taskBlocks.forEach((block) => {
      const taskNumberMatch = block.match(/Task (\d+)/);
      const taskNumber = taskNumberMatch ? parseInt(taskNumberMatch[1], 10) : null;
      if (!taskNumber) return;

      const isCompletion = taskNumber === 1 || taskNumber === 2 || taskNumber === 3 || taskNumber === 8;
      const taskType = isCompletion ? "Code Completion" : "Multiple-Choice Uncommenting";
      const description = ""; // Add detailed descriptions as required

      // Extract submitted code (first uncommented line or completed code)
      const submittedCode = extractSubmittedCode(block, taskType);

      tasks.push({
        taskNumber,
        taskType,
        description,
        submittedCode,
      });
    });

    return { studentSubmission: { tasks } };
  };

  const handleSubmit = async () => {
    console.log("currentQuestionIndex", currentQuestionIndex);
    console.log("studentName", studentName.trim());

    // Validate student name before first submission
    if (currentQuestionIndex === 0 && !studentName.trim()) {
      setNameError("Please enter your name before submitting your answer");

      // Force the state update before scrolling
      await new Promise((resolve) => setTimeout(resolve, 0));

      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        console.warn("Error reference is not set");
      }
      return;
    }

    // Clear any existing errors
    setNameError("");
    setEvaluationError(null);

    setIsEvaluating(true);

    try {
      const question = selectedQuestions[currentQuestionIndex];
      const submission = prepareSubmission(editedCode, question.id);

      const feedbackData = await evaluateCode(submission, question.prompt, question.id);

      setIsEvaluating(false);
      setFeedback(feedbackData);
      setIsSubmitted(true);

      let obtained_marks = 0;

      if (question.id === "q6") {
        // Calculate technical implementation marks
        const technicalMarks = feedbackData.technicalImplementation.criteria.reduce((sum, task) => sum + task.markAwarded, 0);

        // Sum from levelBasedAssessment.levelAwarded
        const levelBasedAssessmentMarks = [
          feedbackData.levelsBasedAssessment.solutionDesign.levelAwarded,
          feedbackData.levelsBasedAssessment.goodProgrammingPractice.levelAwarded,
          feedbackData.levelsBasedAssessment.functionality.levelAwarded,
        ].reduce((sum, level) => sum + level, 0);

        console.log("Updated Marks:", technicalMarks, levelBasedAssessmentMarks);

        // Combine both
        obtained_marks = technicalMarks + levelBasedAssessmentMarks;
      } else {
        obtained_marks = feedbackData?.taskEvaluations.reduce((sum, task) => sum + task.markAwarded, 0);
      }

      console.log("Updated Marks:", obtained_marks);

      setObtainedMarks(obtained_marks);
      setSaveFeedback((prevData) => {
        const newEntry = {
          id: question.id,
          question_marks: question.marks,
          obtained_marks: obtained_marks,
          question: question.text,
          answer: editedCode,
          feedback: feedbackData,
        };

        const updatedData = [...prevData, newEntry];
        console.log("Updated Data:", updatedData); // Log the updated data
        return updatedData;
      });
      const newEntry2 = {
        id: question.id,
        question_marks: question.marks,
        obtained_marks: obtained_marks,
        question: question.text,
        answer: editedCode,
        feedback: feedbackData,
      };

      const requestData = {
        teacherEmail: teacherEmail,
        studentName: studentName,
        feedbackHistory: JSON.stringify(newEntry2),
      };

      const response = await axios.post("https://staging.teepee.ai//teepee_web_server_test/edexel_submit_track", {
        method: "POST",
        body: requestData,
      });
      if (response) {
        console.log("data saved");
      } else {
        console.log("data isnt save");
      }
    } catch (error) {
      console.error("Error during evaluation:", error);
      setIsEvaluating(false);
      setEvaluationError("An error occurred during evaluation. Please try again.");
    }
  };

  const handleCodeChange = (newCode, hasChanged) => {
    setEditedCode(newCode);
    // Check both if code has been edited AND if it's not empty
    const hasContent = newCode.trim().length > 0;
    setIsCodeEdited(hasChanged && hasContent);
  };

  const sendResultsToTeacher = async () => {
    try {
      const results = {
        teacherEmail: teacherEmail,
        studentName: studentName,
        questions: selectedQuestions.map((q, index) => ({
          questionNumber: index + 1,
          questionText: q.text,
          studentCode: editedCode,
          marks: feedback?.totalMark || 0,
          totalPossibleMarks: q.marks,
          feedback: feedback?.explanation || "",
        })),
        totalMarks: selectedQuestions.reduce((total, q) => total + (feedback?.totalMark || 0), 0),
        totalPossibleMarks: selectedQuestions.reduce((total, q) => total + q.marks, 0),
      };

      const response = await fetch("/api/send-results", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(results),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to send results");
      }

      setIsResultsSent(true);
    } catch (error) {
      console.error("Error sending results:", error);
      alert("Failed to send results to teacher. Please try again.");
    }
  };

  const handleNextQuestion = async () => {
    if (currentQuestionIndex < totalQuestions - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      // Reset states for new question

      setEditedCode(selectedQuestions[currentQuestionIndex + 1].initialCode);
      setIsSubmitted(false);
      setFeedback(null);
      setIsEvaluating(false);
      setEvaluationError(null);
      setIsCodeEdited(false);
      window.scrollTo(0, 0);
    } else {
      // On last question, send results
      await sendResultsToTeacher();
      alert("Assessment completed! Results have been sent to the teacher.");
    }
  };

  if (selectedQuestions.length === 0 || !selectedQuestions[currentQuestionIndex]) {
    return <p>Loading questions or no questions selected.</p>;
  }

  const currentQuestion = selectedQuestions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === selectedQuestions.length - 1;

  // pdf code
  const downloadPDF = () => {
    if (!Array.isArray(savefeedback) || savefeedback.length === 0) {
      console.error("Error: savefeedback is undefined or empty.");
      return;
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    const contentWidth = pageWidth - margin * 2;
    let yOffset = margin;

    // Style configurations
    const styles = {
      title: { fontSize: 18, style: "bold", spacing: 15 },
      heading1: { fontSize: 14, style: "bold", spacing: 10 },
      heading2: { fontSize: 12, style: "bold", spacing: 8 },
      normal: { fontSize: 10, style: "normal", spacing: 6 },
      table: { fontSize: 9, style: "normal", spacing: 5 },
      codeEditor: { fontSize: 9, style: "normal", spacing: 8, background: "#f4f4f4", fontFamily: "Courier" },
    };

    const addText = (text, options = {}) => {
      const {
        fontSize = styles.normal.fontSize,
        style = "normal",
        indent = 0,
        spacing = styles.normal.spacing,
        maxWidth = contentWidth - indent,
        fontFamily = "helvetica",
      } = options;

      // Check for page break
      if (yOffset + 20 > pageHeight - margin) {
        doc.addPage();
        yOffset = margin;
      }

      doc.setFontSize(fontSize);
      doc.setFont(fontFamily, style);

      const lines = doc.splitTextToSize(text, maxWidth);
      const textHeight = lines.length * fontSize * 0.3527;

      doc.text(lines, margin + indent, yOffset + fontSize * 0.3527);
      yOffset += textHeight + spacing;

      return yOffset;
    };

    const createTaskTable = (tasks) => {
      // Start on a new page
      doc.addPage();

      // Reset yOffset to top of new page
      yOffset = margin;
      // Check if savefeedback is defined and is an array
      // Ensure tasks is defined and is an array
      if (!Array.isArray(tasks) || tasks.length === 0) {
        console.error("Error: tasks is undefined or empty.");
        return;
      }

      // Add extra spacing before table
      yOffset += 10;

      // Table settings
      const colWidths = {
        task: 110,
        mark: 20,
        status: 40,
      };
      const rowHeight = 25; // Increased row height
      const fontSize = styles.table.fontSize;

      // Add "Task Details:" header with proper spacing
      doc.setFontSize(styles.heading2.fontSize);
      doc.setFont("helvetica", "bold");
      doc.text("Feedback:", margin, yOffset);
      yOffset += 25; // Increased spacing after header

      // Table header
      doc.setFillColor(240, 240, 240);
      doc.rect(margin, yOffset, contentWidth, rowHeight, "FD");

      doc.setFontSize(fontSize);
      doc.setFont("helvetica", "bold");
      let xPos = margin;

      // Header cells with increased vertical spacing
      doc.text("Task", xPos + 2, yOffset + 10); // Increased y-offset
      xPos += colWidths.task;
      doc.text("Mark", xPos + 2, yOffset + 10);
      xPos += colWidths.mark;
      doc.text("Feedback", xPos + 2, yOffset + 10);

      // Draw header lines
      doc.setDrawColor(180, 180, 180);
      doc.line(margin, yOffset, margin + contentWidth, yOffset);
      doc.line(margin, yOffset + rowHeight, margin + contentWidth, yOffset + rowHeight);

      yOffset += rowHeight + 2; // Added extra spacing after header

      // Table rows with increased spacing
      doc.setFont("helvetica", "normal");
      tasks.forEach((task, index) => {
        // Check for page break
        if (yOffset + rowHeight > pageHeight - margin) {
          doc.addPage();
          yOffset = margin + 20; // Increased top margin on new page

          // Redraw header on new page with increased spacing
          doc.setFillColor(240, 240, 240);
          doc.rect(margin, yOffset, contentWidth, rowHeight, "FD");
          doc.setFont("helvetica", "bold");
          xPos = margin;
          doc.text("Task", xPos + 2, yOffset + 10);
          xPos += colWidths.task;
          doc.text("Mark", xPos + 2, yOffset + 10);
          xPos += colWidths.mark;
          doc.text("Feedback", xPos + 2, yOffset + 10);
          doc.line(margin, yOffset, margin + contentWidth, yOffset);
          doc.line(margin, yOffset + rowHeight, margin + contentWidth, yOffset + rowHeight);
          yOffset += rowHeight + 2;
          doc.setFont("helvetica", "normal");
        }

        // Draw row with increased spacing
        xPos = margin;
        const description = doc.splitTextToSize("Task " + task.taskNumber, colWidths.task - 4);
        const feedback = doc.splitTextToSize(task.feedback, colWidths.status - 4); // Split feedback to fit within column width
        const rowHeightAdjusted = Math.max(rowHeight, description.length * fontSize * 0.3527 + 8, feedback.length * fontSize * 0.3527 + 8); // Adjust height for feedback

        // Draw cell backgrounds
        doc.setFillColor(index % 2 === 0 ? 255 : 252, 252, 252);
        doc.rect(margin, yOffset, contentWidth, rowHeightAdjusted, "F");

        // Draw cell content with increased vertical spacing
        doc.text(description, xPos + 2, yOffset + 10);
        xPos += colWidths.task;
        doc.text(task.markAwarded.toString(), xPos + 2, yOffset + 10);
        xPos += colWidths.mark;
        doc.text(feedback, xPos + 2, yOffset + 10); // Render the feedback content

        // Draw cell borders
        doc.setDrawColor(180, 180, 180);
        doc.line(margin, yOffset + rowHeightAdjusted, margin + contentWidth, yOffset + rowHeightAdjusted);

        yOffset += rowHeightAdjusted + 2; // Added extra spacing between rows
      });

      yOffset += 15; // Increased spacing after table
    };

    const createTaskTable_q6 = (tasks) => {
      // Start on a new page
      doc.addPage();

      // Reset yOffset to top of new page
      yOffset = margin;

      // Ensure tasks is defined and is an array
      if (!Array.isArray(tasks) || tasks.length === 0) {
        console.error("Error: tasks is undefined or empty.");
        return;
      }

      // Add extra spacing before table
      yOffset += 10;

      // Calculate available width based on page width and margins
      const availableWidth = doc.internal.pageSize.getWidth() - margin * 2;

      // Adjusted column widths to fit within page width
      const colWidths = {
        task: Math.floor(availableWidth * 0.15), // 15% of available width
        mark: Math.floor(availableWidth * 0.1), // 10% of available width
        description: Math.floor(availableWidth * 0.375), // 37.5% of available width
        evidence: Math.floor(availableWidth * 0.375), // 37.5% of available width
      };

      const rowHeight = 30;
      const fontSize = styles.table.fontSize;
      const padding = 3;

      // Add header
      doc.setFontSize(styles.heading2.fontSize);
      doc.setFont("helvetica", "bold");
      doc.text("Feedback:", margin, yOffset);
      yOffset += 15;

      // Table header
      doc.setFillColor(240, 240, 240);
      doc.rect(margin, yOffset, availableWidth, rowHeight, "FD");

      doc.setFontSize(fontSize);
      doc.setFont("helvetica", "bold");
      let xPos = margin;

      // Header cells
      doc.text("Task", xPos + padding, yOffset + 15);
      xPos += colWidths.task;
      doc.text("Mark", xPos + padding, yOffset + 15);
      xPos += colWidths.mark;
      doc.text("Description", xPos + padding, yOffset + 15);
      xPos += colWidths.description;
      doc.text("Evidence", xPos + padding, yOffset + 15);

      // Draw header borders (only horizontal)
      doc.setDrawColor(180, 180, 180);
      doc.line(margin, yOffset, margin + availableWidth, yOffset);
      doc.line(margin, yOffset + rowHeight, margin + availableWidth, yOffset + rowHeight);

      yOffset += rowHeight;

      // Table rows
      doc.setFont("helvetica", "normal");
      tasks.forEach((task, index) => {
        // Check for page break
        if (yOffset + rowHeight > doc.internal.pageSize.getHeight() - margin * 2) {
          doc.addPage();
          yOffset = margin + 20;
        }

        // Calculate wrapped text
        const taskText = doc.splitTextToSize(`Task ${task.criterionNumber}`, colWidths.task - padding * 2);
        const descriptionText = doc.splitTextToSize(task.description, colWidths.description - padding * 2);
        const evidenceText = doc.splitTextToSize(task.evidence || "No evidence provided", colWidths.evidence - padding * 2);

        // Calculate row height based on content
        const textHeight = Math.max(taskText.length * fontSize * 1.2, descriptionText.length * fontSize * 1.2, evidenceText.length * fontSize * 1.2);
        const rowHeightAdjusted = Math.max(rowHeight, textHeight + padding * 2);

        // Row background
        doc.setFillColor(index % 2 === 0 ? 255 : 248, 248, 248);
        doc.rect(margin, yOffset, availableWidth, rowHeightAdjusted, "F");

        // Cell content
        xPos = margin;
        doc.text(taskText, xPos + padding, yOffset + padding + fontSize);

        xPos += colWidths.task;
        doc.text(task.markAwarded.toString(), xPos + padding, yOffset + padding + fontSize);

        xPos += colWidths.mark;
        doc.text(descriptionText, xPos + padding, yOffset + padding + fontSize);

        xPos += colWidths.description;
        doc.text(evidenceText, xPos + padding, yOffset + padding + fontSize);

        // Draw only horizontal border at bottom of row
        doc.setDrawColor(180, 180, 180);
        doc.line(margin, yOffset + rowHeightAdjusted, margin + availableWidth, yOffset + rowHeightAdjusted);

        yOffset += rowHeightAdjusted;
      });

      yOffset += 20;
    };

    // Start building the PDF
    savefeedback.forEach((feedback, index) => {
      if (index > 0) {
        doc.addPage();
        yOffset = margin;
      }

      // Title Section
      if (index === 0) {
        addText("Feedback Report", { ...styles.title });
      }

      // Improved "Obtained Marks" section
      // addText(`Obtained Marks: ${feedback.obtained_marks} / ${feedback.question_marks}`, {
      //   ...styles.normal,
      //   bold: true,
      //   fontSize: 12,
      //   color: [0, 102, 204]
      // });
      // Question Section
      addText(
        `Question ${index + 1}                                                               Obtained Marks: ${feedback.obtained_marks} / ${
          feedback.question_marks
        }`,
        { ...styles.heading1 }
      );

      // Scenario Section
      addText(feedback.question, { ...styles.normal, indent: 5 });

      // New function to handle code editor content with page breaks
      const addCodeEditor = (code, options = {}) => {
        const {
          fontSize = styles.codeEditor.fontSize,
          fontFamily = styles.codeEditor.fontFamily,
          lineHeight = fontSize * 0.3527,
          padding = 10,
        } = options;

        doc.setFontSize(fontSize);
        doc.setFont(fontFamily, styles.codeEditor.style);

        // Split the code into lines that fit the content width
        const lines = doc.splitTextToSize(code, contentWidth - padding * 2);
        let currentLine = 0;

        while (currentLine < lines.length) {
          // Calculate remaining space on current page
          const remainingSpace = pageHeight - margin - yOffset;

          // Calculate how many lines can fit on current page
          const linesPerPage = Math.floor((remainingSpace - padding * 2) / lineHeight);

          // If we can't fit at least one line, start a new page
          if (linesPerPage < 1) {
            doc.addPage();
            yOffset = margin;
            continue;
          }

          // Calculate lines for current page
          const currentPageLines = lines.slice(currentLine, currentLine + linesPerPage);
          const boxHeight = currentPageLines.length * lineHeight + padding * 2;

          // Draw background for code editor
          doc.setFillColor(244, 244, 244);
          doc.rect(margin, yOffset, contentWidth, boxHeight, "F");

          // Draw border
          doc.setDrawColor(200, 200, 200);
          doc.rect(margin, yOffset, contentWidth, boxHeight);

          // Add code text
          doc.setTextColor(0, 0, 0);
          currentPageLines.forEach((line, index) => {
            doc.text(line, margin + padding, yOffset + padding + index * lineHeight);
          });

          // Update position and line counter
          yOffset += boxHeight + styles.codeEditor.spacing;
          currentLine += linesPerPage;

          // Add new page if there are more lines
          if (currentLine < lines.length) {
            doc.addPage();
            yOffset = margin;
          }
        }

        return yOffset;
      };

      // Answer Section: (Code Editor Box)
      addText("", { ...styles.heading2 });
      const answerText = feedback.answer ? feedback.answer : "No answer provided.";
      addCodeEditor(answerText, {
        fontSize: styles.codeEditor.fontSize,
        fontFamily: styles.codeEditor.fontFamily,
      });

      // Task Section
      if (feedback.id === "q6") {
        addText("", { ...styles.heading2 });
        console.log("Sm 6", feedback.feedback.technicalImplementation.criteria);
        createTaskTable_q6(feedback.feedback.technicalImplementation.criteria);
      } else {
        addText("", { ...styles.heading2 });
        createTaskTable(feedback.feedback?.taskEvaluations);
      }

      if (feedback.feedback?.suggestions?.length > 0) {
        addText("How to Improve:", { ...styles.heading2 });
        feedback.feedback.suggestions.forEach((suggestion) => {
          addText(`• ${suggestion}`, { ...styles.normal, indent: 5 });
        });
      }
      
      // Suggestions Section

      if (recheck[currentQuestionIndex]) {
        addText("AI Recheck Feedback:", { ...styles.heading2 });
        addText(feedback.updated_feedback, { ...styles.normal, indent: 5 });
      }
    });

    // Save the PDF with a file name
    doc.save(`${studentName}_feedback_report.pdf`);
  };
  const handleRecheck = async () => {
    setOpen(true);
  };
  return (
    <div className="container mx-auto p-6 max-w-screen-2xl">
      <RecheckSidePanel
        open={open}
        selectedQuestion={currentQuestionIndex}
        totalQuestions={totalQuestions}
        selectedQuizQuestion={currentQuestion.text}
        finalTotalMark={obtainedMarks}
        feedbackAI={feedback}
        studentAsnwer={editedCode}
        closePanel={() => {
          setOpen(false);
        }}
        setRecheck={setRecheck}
        recheck={recheck}
        setSaveFeedback={setSaveFeedback}
      />
      <Card>
        <CardHeader>
          <CardTitle>Student Assessment - Edexcel GCSE P2 Assessment & Practice Tool by <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Teepee.ai
            </a></CardTitle>
        </CardHeader>
        <CardContent>
          <div className="student-assessment relative">
            {isEvaluating && <LoadingOverlay />}

            {currentQuestionIndex === 0 && (
              <div className="mb-6">
                <div className="flex items-center space-x-2 mb-2">
                  <Label htmlFor="studentName" className="text-lg font-bold text-blue-800">
                    Student Name
                  </Label>
                  <span className="text-red-500">*</span>
                </div>
                <Input
                  id="studentName"
                  type="text"
                  value={studentName}
                  onChange={(e) => {
                    setStudentName(e.target.value);
                    setNameError("");
                  }}
                  placeholder="Enter your full name"
                  className={`w-full max-w-2xl ${nameError ? "border-red-500" : ""}`}
                  disabled={isSubmitted} // Prevent name changes after first submission
                  maxLength={45}
                />
                {nameError && (
                  <Alert variant="destructive" className="bg-red-50 border-red-200" ref={errorRef}>
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription className="flex items-center">
                      <span className="ml-2">{nameError}</span>
                    </AlertDescription>
                  </Alert>
                )}
              </div>
            )}

            <div className="question-wrapper">
              <h2 className="text-xl font-semibold mb-4">
                Question {currentQuestionIndex + 1} (of {totalQuestions})
              </h2>
              <div className="mb-4">
                {currentQuestion.text.split("\n").map((line, index) => {
                  // Handle bullet points
                  if (line.trim().startsWith("-")) {
                    return (
                      <div key={index} className="ml-4">
                        <span className="inline-block w-2 h-2 rounded-full bg-gray-400 mr-2" />
                        {line.trim().substring(1)}
                      </div>
                    );
                  }
                  // Handle section headers (like "Task")
                  else if (line.trim() && !line.trim().startsWith("[")) {
                    return (
                      <p key={index} className="mb-4">
                        {line.trim()}
                      </p>
                    );
                  }
                  // Handle image placeholder
                  else if (line.includes("[Display image:")) {
                    // Extract the image path from the text
                    const imagePath = line.match(/\[(Display image: .*?)\]/)[1];

                    // Choose the correct image based on the path
                    let imageSource = temperatureTable; // Default to temperature table
                    if (imagePath.includes("temperature_table.png")) {
                      imageSource = temperatureTable;
                    }
                    // Add more image conditions here if needed for other questions

                    return (
                      <div key={index} className="my-4">
                        <img src={imageSource} alt="Question reference" className="max-w-[400px] h-auto rounded-md border border-gray-200" />
                      </div>
                    );
                  }
                  // Return empty div for empty lines to maintain spacing
                  return <div key={index} className="h-4" />;
                })}
              </div>

              <CodeEditor initialCode={currentQuestion.initialCode} onCodeChange={handleCodeChange} value={editedCode} readOnly={isSubmitted} />

              {evaluationError && <div className="my-4 p-4 bg-red-50 border border-red-200 rounded text-red-600">{evaluationError}</div>}

              <div className="mt-4 space-x-4">
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitted || isEvaluating || !isCodeEdited || !editedCode.trim()}
                  variant={isSubmitted || isEvaluating || !isCodeEdited || !editedCode.trim() ? "secondary" : "default"}
                >
                  {isEvaluating ? "Evaluating..." : "Submit for Marking"}
                </Button>
                {isSubmitted && feedback && (
                  <Button
                    style={{ background: !recheck[currentQuestionIndex] ? "#6750A4" : "grey" }}
                    className="float-right  text-white"
                    onClick={handleRecheck}
                    disabled={recheck[currentQuestionIndex]}
                    variant={recheck ? "secondary" : "default"}
                  >
                    Request Recheck
                  </Button>
                )}
                {!isLastQuestion && (
                  <Button onClick={handleNextQuestion} disabled={!isSubmitted} variant={!isSubmitted ? "secondary" : "default"}>
                    Next Question
                  </Button>
                )}
              </div>

              {isSubmitted && feedback && (
                <FeedbackDisplay
                  feedback={feedback}
                  calculatedTotalMark={recheck[currentQuestionIndex]?.u_marks ? recheck[currentQuestionIndex]?.u_marks : obtainedMarks}
                  finalTotalMark={currentQuestion.marks}
                  questionId={currentQuestion.id}
                  totalQuestions={totalQuestions}
                  currentQuestionIndex={currentQuestionIndex}
                  onNextQuestion={handleNextQuestion}
                  onSendResults={sendResultsToTeacher}
                  recheck={recheck}
                />
              )}

              {isLastQuestion && isSubmitted && (
                <div className="mt-8 p-6 bg-green-50 border border-green-200 rounded-lg">
                  <h3 className="text-xl font-semibold text-green-800 mb-4">🎉 Congratulations, {studentName}!</h3>
                  <p className="text-green-700 mb-4">You have completed all {totalQuestions} questions in this assessment.</p>
                </div>
              )}

              {isLastQuestion && isSubmitted && (
                <div className="flex justify-center mt-8 space-x-12">
                  {" "}
                  {/* Added space-x-4 for spacing between buttons */}
                  <Button
                    onClick={async () => {
                      try {
                        setIsLoading(true);

                        const requestData = {
                          body: {
                            teacherEmail: teacherEmail,
                            studentName: studentName,
                            feedbackHistory: savefeedback,
                          },
                        };

                        const response = await fetch("https://staging.teepee.ai/teepee_web_server_test/edexel_email", {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify(requestData),
                        });

                        if (!response.ok) {
                          const errorData = await response.json();
                          throw new Error(errorData.error || "Failed to send results");
                        }

                        setIsResultsSent(true);
                        setIsLoading(false);
                      } catch (error) {
                        console.error("Error sending results:", error);
                        setIsLoading(false);
                        alert("Failed to send results to teacher. Please try again.");
                      }
                    }}
                    className="bg-green-600 hover:bg-green-700"
                    disabled={isResultsSent || isLoading}
                  >
                    {isLoading ? "Sending..." : isResultsSent ? "Results Sent!" : "Share Assessment with Teacher"}
                  </Button>
                  <Button onClick={downloadPDF} className="bg-blue-600 hover:bg-blue-700">
                    Download PDF
                  </Button>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
export default StudentAssessment;
