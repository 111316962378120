import React, { useState } from "react";
import CodeEditor from "../components/CodeEditor";
import EvaluationResults from "../components/EvaluationResults";
import { evaluationService } from "../services/EvaluationService";
import { Loader2 } from "lucide-react";

export default function QuestionCard({ question, email, emailText }) {
  const [code, setCode] = useState(question.initialCode);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null); // No type annotations

  const isCodeValid = () => {
    return code.trim() !== "" && code.trim() !== question.initialCode.trim();
  };

  const handleSubmit = async () => {
    if (!isCodeValid()) {
      setError("Please modify the code before submitting.");
      return;
    }

    setIsSubmitting(true);
    setError(null);
    try {
      const evaluationResult = await evaluationService.evaluate(
        code,
        question.evaluationConfig,
        question.id
      );
      setResult(evaluationResult);

      const payload = {
        body: {
          email: emailText,
          questions: [question.id.toString()]
        }
      };

      // First, send email notification
      try {
        // Call the email notification API
        const emailNotificationResponse = await fetch('https://staging.teepee.ai/teepee_web_server_test/igcse_email_notifier', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
      
        if (!emailNotificationResponse.ok) {
          throw new Error('Failed to send email notification');
        }
      
        console.log('Email notification sent successfully!');
      } catch (error) {
        console.error('Error:',error);
      }
    } catch (error) {
      console.error("Submission failed:", error);
      setError("Failed to evaluate code. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log("dasdasdasds",email)

  return (
    <div className="border rounded-lg p-6 mb-8 bg-background">
      <div className="flex items-center gap-4 mb-4">
        <span className="text-sm text-foreground/60">
          {question.marks} marks
        </span>
      </div>

      <div className="space-y-4">
        <h2 className="text-xl whitespace-pre-wrap">
          {question.text.split("```").map((part, index) =>
            index % 2 === 0 ? (
              <span key={index}>{part}</span>
            ) : (
              <pre
                key={index}
                className="font-mono bg-gray-50 p-4 rounded-md text-sm overflow-x-auto"
              >
                {part}
              </pre>
            )
          )}
        </h2>
      </div>

      <div className="mb-4">
        <CodeEditor code={code} onChange={setCode} height="200px" />
      </div>

      <button
      
        onClick={handleSubmit}
        disabled={isSubmitting || !isCodeValid() || email}
        className="rounded-full border border-solid border-transparent transition-colors flex items-center justify-center bg-foreground text-background gap-2 hover:bg-[#383838] dark:hover:bg-[#ccc] text-sm sm:text-base h-10 sm:h-12 px-4 sm:px-5 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isSubmitting && <Loader2 className="w-4 h-4 animate-spin" />}
        {isSubmitting ? "Evaluating..." : "Submit"}
      </button>

      {error && (
        <div className="mt-4 p-4 bg-red-100 text-red-800 rounded-lg">
          {error}
        </div>
      )}

      {result && <EvaluationResults result={result} />}
    </div>
  );
}
