import React, { useEffect, useState } from "react";
import TeepeeLogo from "../images/logo/white-logo.svg";
import axios from "axios";
import SubjectImage from "../images/subject_image.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import SecondSubjectImage from "../images/subject_image_2.svg";
import ThirdSubjectImage from "../images/subject_image_3.svg";
import HeroSectionTransitionBottom from "../images/hero_section_transition.svg";
import HeroSectionTransitionTop from "../images/hero_section_transition_top.svg";
import HowItWorksTransitionLeft from "../images/left_image.svg";
import HowItWorksTransitionRight from "../images/right_image.svg";
// import YouTubeEmbed from "../Components/LandingPage.jsx/YouTubeEmbed";

const HowItWorksStep = ({ number, title, description, isRight = false }) => (
  <div className={` relative ${isRight ? "flex-row-reverse" : ""}`}>
    <div className="w-8 h-8 rounded-full bg-black text-white flex items-center justify-center flex-shrink-0 relative z-10 mb-[14px]">{number}</div>
    <div className={`text-${isRight ? "right" : "left"} max-w-xs`}>
      <h3 className="font-bold text-[20px] mb-1">{title}</h3>
      <p className="text-gray-600 text-[16px]">{description}</p>
    </div>
  </div>
);

const Logo = () => (
  <div className="mb-1 pt-6 pl-20 inline-block">
    <img className="w-[56vw] md:w-[15vw] cursor-pointer" src={TeepeeLogo} alt="logo" />
    <div className="flex justify-end text-white cursor-pointer" onClick={() => window.open("https://teepee.ai", "_blank")}>
      <div className="mr-1 text-[12px] flex items-center">by</div>
      <div>Teepee.ai</div>
    </div>
  </div>
);

const SubjectCard = ({ title, bgColor, imgSrc, subject_id, url }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`rounded-xl h-64  overflow-hidden shadow-lg ${bgColor} text-white p-6 pb-0 cursor-pointer hover:shadow-xl transition-shadow relative group`}
      onClick={() => {
        window.sessionStorage.setItem("subject_id", subject_id);
        console.log(window.sessionStorage.getItem("subject_id"));
        navigate(url);
      }}
    >
      <h3 className="text-xl font-semibold mb-4 text-center h-[20%]">{title}</h3>
      <div className="flex items-center justify-center overflow-hidden">
        <img
          src={imgSrc}
          alt={title}
          className="w-full h-40 object-contain rounded-lg transform group-hover:scale-105 transition-transform duration-300"
        />
      </div>
    </div>
  );
};

const LandingPage = () => {
  // const getSubject = () => {
  //   axios
  //     .request({
  //       method: "GET",
  //       url: process.env.REACT_APP_REST_API_BASE_URL + apiConfig.endpoints.getSubjects,
  //       headers: {
  //         "Content-Type": "application/json; charset=utf-8",
  //       },
  //     })
  //     .then((res) => {
  //       setSubjects(res.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // useEffect(() => {
  //   getSubject();
  // }, []);

  const colors = ["bg-[rgb(44,44,44)]", "bg-[rgb(85,77,183)]", "bg-[rgb(61,153,154)]"]; // Add more colors as needed
  const images = [SubjectImage, SecondSubjectImage, ThirdSubjectImage]; // Replace these with actual image imports or paths

  const subjects = [
    {
      subject_name: "Edexcel GCSE Computer Science (1CP2)",
      subject_id: 1,
      url: "/GCSE",
    },
    {
      subject_name: "Edexcel IGCSE Computer Science",
      subject_id: 2,
      url: "/IGCSE",
    },
  ];
  return (
    <div>
      <div className="min-h-screen bg-white">
        {/* Hero Section */}
        <div className="relative">
          <div className="bg-[#362271] py-12">
            {/* Logo */}
            {/* <div className="mb-12">
              <Logo />
            </div> */}
            <div className="max-w-5xl mx-auto p-8">
              {/* Hero Content */}
              <div className="text-center mx-auto">
                <h1 className="text-[24px] md:text-[40px] font-bold mb-[12px] text-white">
                  Effortless
                  <span className="text-[#FFC727]"> Coding Practice & Assessments</span> for Edexcel GCSE & IGCSE
                </h1>
                <p className="text-[#FFFFFF] text-[14px] md:text-[24px] text-white relative z-10	">
                  Teachers prepared coding exam questions, and students receive instant AI Marking & feedback.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden md:block absolute bottom-0">
            <img src={HeroSectionTransitionBottom} />
          </div>
          <div className="hidden md:block absolute right-0 top-0">
            <img src={HeroSectionTransitionTop} />
          </div>
        </div>
        {/* <div className="pt-10 flex justify-center">
          <div className="w-[90vw] md:w-[60vw]">
            <YouTubeEmbed videoId={"tYVeVqYlgjw"} />
          </div>
        </div> */}
        {/* How It Works Section */}

        {/* Subject Selection Section */}
        <div className="max-w-8xl mx-auto md:px-24 pb-24 py-12  ">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Select a Subject to Begin</h2>
            <p className="text-gray-600 text-lg">
              Share coding practice exams to help your students revise effectively with instant AI marking and feedback.
            </p>
          </div>
          <div className={`${subjects.length === 1 ? "flex justify-center" : ""}`}>
            <div
              className={`grid grid-cols-1 px-[5%] ${
                subjects.length === 1 ? "md:grid-cols-1" : subjects.length === 2 ? "md:grid-cols-2" : "md:grid-cols-3"
              } gap-8`}
            >
              {subjects.map((subject, index) => {
                const bgColor = colors[index % colors.length]; // Cycle through colors
                const imgSrc = images[index % images.length]; // Cycle through images
                return (
                  <div key={index}>
                    <SubjectCard title={subject.subject_name} bgColor={bgColor} imgSrc={imgSrc} subject_id={subject.subject_id} url={subject.url} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Footer */}
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default LandingPage;
