class SubmissionFormatService {
  // Helper for Q1 - extracts block of code (condition + print)
  static extractBlock(lines, startPattern) {
      const index = lines.findIndex(line => line.includes(startPattern));
      if (index === -1 || index + 1 >= lines.length) return null;

      // Get both the condition line and its print statement
      return `${lines[index]}\n${lines[index + 1]}`;
  }

  // Helper for Q2 - extracts single lines with special handling for modulus
  static extractQ2Code(code, searchPatterns) {
      const lines = code.replace(/\r\n/g, '\n').replace(/\r/g, '\n').split('\n');

      // Debug for modulus task
      if (searchPatterns[0].trim().toUpperCase() === 'MODULUS') {
          console.log('\n=== MODULUS TASK DEBUG ===');
          console.log('Search patterns:', searchPatterns);

          for (const line of lines) {
              const trimmedLine = line.trim();
              if (trimmedLine.includes('%') && trimmedLine.includes('100')) {
                  console.log('Found potential modulus line:', trimmedLine);
                  console.log('Line contains %:', trimmedLine.includes('%'));
                  console.log('Line contains 100:', trimmedLine.includes('100'));
                  console.log('Line contains ==:', trimmedLine.includes('=='));
                  console.log('Line contains 0:', trimmedLine.includes('0'));
                  return trimmedLine;
              }
          }
          console.log('No modulus line found in code');
          return null;
      }

      // First try to find non-comment matches
      for (const line of lines) {
          const trimmedLine = line.trim();
          if (!trimmedLine.startsWith('#')) {  // Skip comments in first pass
              for (const pattern of searchPatterns) {
                  if (trimmedLine.includes(pattern)) {
                      return trimmedLine;
                  }
              }
          }
      }

      // If no non-comment match found, fall back to comment matches (for backwards compatibility)
      for (const line of lines) {
          const trimmedLine = line.trim();
          for (const pattern of searchPatterns) {
              if (trimmedLine.includes(pattern)) {
                  return trimmedLine;
              }
          }
      }

      return null;
  }

  // Helper method to extract multi-line conditional blocks
  static extractConditionalBlock(code, startPattern) {
      const lines = code.split('\n');
      const startIndex = lines.findIndex(line => {
          console.log('Checking line:', line);
          console.log('Against pattern:', startPattern);
          console.log('Match?:', line.includes(startPattern));
          return line.includes(startPattern);
      });

      if (startIndex === -1) return '';

      const block = [];
      let currentIndex = startIndex;
      const indentLevel = (lines[startIndex].match(/^\s*/)?.[0] || '').length;

      // Add the first line
      block.push(lines[currentIndex].trim());

      // Add subsequent indented lines
      while (currentIndex + 1 < lines.length) {
          currentIndex++;
          const line = lines[currentIndex];
          const lineIndent = (line.match(/^\s*/)?.[0] || '').length;
          if (lineIndent > indentLevel && !line.trim().startsWith('#')) {
              block.push(line.trim());
          } else if (lineIndent <= indentLevel) {
              break;
          }
      }

      return block.join('\n');
  }

  static formatQ1Submission(code) {
      console.log('\n=== ANALYZING Q1 CODE ===');
      const lines = code.split('\n').map(line => line.trim());
      console.log('Full submitted code:', code);

      const ifBlock = this.extractBlock(lines, 'if num1') || '';
      const elifBlock = this.extractBlock(lines, 'elif') || '';
      const elseBlock = this.extractBlock(lines, 'eles') || this.extractBlock(lines, 'else') || '';

      const tasks = [
          {
              taskNumber: 1,
              taskType: "Code Correction",
              description: "Fix equality operator in if statement",
              submittedCode: ifBlock,
              evaluationNotes: "Check if '==' is used instead of '=' in the if condition"
          },
          {
              taskNumber: 2,
              taskType: "Code Correction",
              description: "Fix logical error in elif statement - number printed as larger must be mathematically larger",
              submittedCode: elifBlock,
              evaluationNotes: "When num1 < num2, num2 is the larger number, so must print 'num2 is larger than num1'. When num1 > num2, num1 is the larger number, so must print 'num1 is larger than num2'."
          },
          {
              taskNumber: 3,
              taskType: "Code Correction",
              description: "Fix spelling of 'else' keyword",
              submittedCode: elseBlock,
              evaluationNotes: "Check if 'else' is spelled correctly (not 'eles')"
          }
      ];

      console.log('\n=== EXTRACTED BLOCKS ===');
      tasks.forEach(task => {
          console.log(`\nTask ${task.taskNumber}:`);
          console.log('Submitted code:\n', task.submittedCode);
      });

      return {
          studentSubmission: {
              tasks
          }
      };
  }
  static formatQ2Submission(code) {
    console.log('\n=== ANALYZING Q2 CODE ===');
    console.log('Full submitted code:\n', code);

    // Extract relevant code sections for each task
    console.log('\nExtracting modulus condition...');
    const modulusCondition = this.extractQ2Code(code, ['MODULUS']);
    console.log('Extracted modulus condition:', modulusCondition);

    const functionInterface = this.extractQ2Code(code, ['def checkFactor']) || '';
    const zeroCondition = this.extractQ2Code(code, ['if pNumber <= 0']) || '';
    const checkAssignment = this.extractQ2Code(code, ['check = True', 'check = False']) || '';
    const inputConversion = this.extractQ2Code(code, ['int(input']) || '';
    const inputPrompt = this.extractQ2Code(code, [
        'print("Enter a number: "',
        'input("Enter a number: "'
    ]) || '';
    const functionCall = this.extractQ2Code(code, [
        'result = checkFactor',
        'checkFactor(number)'
    ]) || '';
    const outputMessage = this.extractQ2Code(code, ['is a factor of 100', 'is not a factor of 100']) || '';

    const tasks = [
        {
            taskNumber: 1,
            taskType: "Implementation",
            description: "Function interface is correct",
            submittedCode: functionInterface,
            evaluationNotes: "Check if function is defined as 'def checkFactor(pNumber)'"
        },
        {
            taskNumber: 2,
            taskType: "Implementation",
            description: "Condition correct for numbers <= 0",
            submittedCode: zeroCondition,
            evaluationNotes: "Check if condition 'pNumber <= 0' is implemented"
        },
        {
            taskNumber: 3,
            taskType: "Implementation",
            description: "Modulus condition correct",
            submittedCode: modulusCondition || '',
            evaluationNotes: "Check if modulus operator (%) is used with 100"
        },
        {
            taskNumber: 4,
            taskType: "Implementation",
            description: "Check variable appropriately set",
            submittedCode: checkAssignment,
            evaluationNotes: "Check if 'check' is set to True/False"
        },
        {
            taskNumber: 5,
            taskType: "Implementation",
            description: "Check returned",
            submittedCode: this.extractQ2Code(code, [
                'return check',
                'return'
            ]) || '',
            evaluationNotes: "Check if the function returns the check variable"
        },
        {
            taskNumber: 6,
            taskType: "Implementation",
            description: "Input stored as integer",
            submittedCode: inputConversion,
            evaluationNotes: "Check if input is converted to integer"
        },
        {
            taskNumber: 7,
            taskType: "Implementation",
            description: "Input includes space/colon/newline",
            submittedCode: inputPrompt,
            evaluationNotes: "Check input prompt formatting"
        },
        {
            taskNumber: 8,
            taskType: "Implementation",
            description: "Function called correctly",
            submittedCode: functionCall,
            evaluationNotes: "Check if function is called with input"
        },
        {
            taskNumber: 9,
            taskType: "Implementation",
            description: "Display includes number and message",
            submittedCode: outputMessage,
            evaluationNotes: "Check output message formatting"
        },
        {
            taskNumber: 10,
            taskType: "Implementation",
            description: "Compiles without syntax errors",
            submittedCode: code,  // Need full code for syntax check
            evaluationNotes: "Check for syntax errors"
        },
        {
            taskNumber: 11,
            taskType: "Implementation",
            description: "Executes with correct output",
            submittedCode: code,  // Need full code for execution check
            evaluationNotes: "Check overall functionality"
        }
    ];

    console.log('\n=== EXTRACTED TASKS ===');
    tasks.forEach(task => {
        console.log(`\nTask ${task.taskNumber}:`);
        console.log('Description:', task.description);
        console.log('Extracted code:', task.submittedCode);
        console.log('Evaluation notes:', task.evaluationNotes);
    });

    return {
        studentSubmission: {
            tasks
        }
    };
}


static formatQ3Submission(code) {
  console.log('\n=== TASK 3 DEBUG ===');

  // Task 3 - Letter check (keep existing code)
  const letterPatterns = [
      '[0] in alphabet',
      '[0].lower() in alphabet',
      '[0] in alphabet or',
      'if.*[0] in alphabet.*lower()',
      '[0].isalpha()',
      '[0] in "ABCDEFGHIJKLMNOPQRSTUVWXYZ"'
  ];
  const letterPattern = letterPatterns.find(pattern => code.includes(pattern));
  const letterCheck = letterPattern ?
      this.extractConditionalBlock(code, letterPattern) : '';

  // Task 4 - Digit check (new code)
  const digitPatterns = [
      'in digit',
      'in "0123456789"',
      'isdigit()',
      'in range("0", "9")'
  ];
  const digitPattern = digitPatterns.find(pattern => code.includes(pattern));
  const digitCheck = digitPattern ?
      this.extractConditionalBlock(code, digitPattern) : '';

  // Task 5 - Conditional logic check
  let conditionalLogic = this.extractQ2Code(code, [
      'if id[0] in alphabet'  // Find the start of the block
  ]) || '';

  // If we found the start, get the full nested structure
  if (conditionalLogic) {
      const lines = code.split('\n');
      const startIndex = lines.findIndex(line => line.includes('if id[0] in alphabet'));
      if (startIndex !== -1) {
          const block = [];
          const currentIndent = (lines[startIndex].match(/^\s*/)?.[0] || '').length;

          // Add the if statement and all its nested content
          for (let i = startIndex; i < lines.length; i++) {
              const line = lines[i];
              const lineIndent = (line.match(/^\s*/)?.[0] || '').length;
              if (lineIndent >= currentIndent && line.trim()) {
                  block.push(line.trim());
              }
              if (lineIndent <= currentIndent && i > startIndex) {
                  break;
              }
          }
          conditionalLogic = block.join('\n');
      }
  }

  console.log('Extracted conditional logic:', conditionalLogic);

  const tasks = [
      {
          taskNumber: 1,
          taskType: "Implementation",
          description: "Variable to store the number of invalid IDs",
          submittedCode: this.extractQ2Code(code, [
              '= 0',  // Look for any variable initialization to 0
              '+= 1'  // Also check for increment patterns as backup
          ]) || '',
          evaluationNotes: "Check if variable is declared to store invalid count"
      },
      {
          taskNumber: 2,
          taskType: "Implementation",
          description: "Loop used to iterate through IDs in the file",
          submittedCode: (() => {
              const lines = code.split('\n');
              for (let i = 0; i < lines.length; i++) {
                  const line = lines[i].trim();
                  if (line.includes('open(') && line.includes('=')) {
                      const fileVar = line.split('=')[0].trim();
                      for (let j = i + 1; j < Math.min(i + 5, lines.length); j++) {
                          if (lines[j].includes('for') && lines[j].includes(fileVar)) {
                              return lines[j].trim();
                          }
                      }
                  }
              }
              return this.extractQ2Code(code, [
                  'for line in',
                  'for id in',
                  'for record in'
              ]) || '';
          })(),
          evaluationNotes: "Check if loop is used to iterate through file"
      },
      {
          taskNumber: 3,
          taskType: "Implementation",
          description: "Each ID checked to see if the first character is a letter",
          submittedCode: letterCheck,
          evaluationNotes: "Check if first character letter validation is implemented"
      },
      {
          taskNumber: 4,
          taskType: "Implementation",
          description: "Each ID checked to see if it contains at least one numeric digit",
          submittedCode: digitCheck,
          evaluationNotes: "Check if numeric digit validation is implemented"
      },
      {
          taskNumber: 5,
          taskType: "Implementation",
          description: "Only checks for digits if first character is a letter OR only checks if first character is a letter if at least one digit is present",
          submittedCode: (() => {
              const lines = code.split('\n');
              const startIndex = lines.findIndex(line =>
                  line.includes('if') &&
                  (line.includes('.isalpha()') ||
                      line.includes('in alphabet') ||
                      (line.includes('[0]') && line.includes('letter')))
              );

              if (startIndex === -1) return '';

              const block = [];
              const baseIndent = (lines[startIndex].match(/^\s*/) || [''])[0].length;

              let currentIndex = startIndex;

              while (currentIndex < lines.length) {
                  const line = lines[currentIndex];
                  const indent = (line.match(/^\s*/) || [''])[0].length;
                  if (indent < baseIndent && currentIndex > startIndex) break;
                  if (line.trim()) {
                      block.push(line.trim());
                  }
                  currentIndex++;
              }

              return block.join('\n');
          })(),
          evaluationNotes: "Check if conditional logic is correctly implemented"
      },
      {
          taskNumber: 6,
          taskType: "Implementation",
          description: "Number of invalid IDs incremented correctly",
          submittedCode: (() => {
              const initLine = this.extractQ2Code(code, ['= 0']) || '';
              const varName = initLine ? initLine.split('=')[0].trim() : '';

              const incrementPatterns = [
                  '+= 1'
              ];

              if (varName) {
                  incrementPatterns.push(`${varName} = ${varName} + 1`);
              } else {
                  const lines = code.split('\n');
                  for (const line of lines) {
                      const trimmed = line.trim();
                      if (trimmed.includes('=') && trimmed.includes('+ 1')) {
                          const parts = trimmed.split('=');
                          const varName = parts[0].trim();
                          if (parts[1].trim().startsWith(varName)) {
                              return trimmed;
                          }
                      }
                  }
              }
              return this.extractQ2Code(code, incrementPatterns)?.trim() || '';
          })(),
          evaluationNotes: "Check if invalid count is incremented properly"
      },
      {
          taskNumber: 7,
          taskType: "Implementation",
          description: "All invalid IDs displayed",
          submittedCode: (() => {
              const loopLine = this.extractQ2Code(code, [
                  'for id in',
                  'for line in',
              ]) || '';
              if (!loopLine) return '';

              const varName = loopLine.split(' ')[1];

              return this.extractQ2Code(code, [
                  `print(${varName}`,
                  `print(f"`,
                  `print("`,
              ])?.trim() || '';
          })(),
          evaluationNotes: "Check if invalid IDs are displayed"
      },
      {
          taskNumber: 8,
          taskType: "Implementation",
          description: "Text file closed",
          submittedCode: this.extractQ2Code(code, [
              '.close()',
              'close()'
          ]) || '',
          evaluationNotes: "Check if file is properly closed"
      },
      {
          taskNumber: 9,
          taskType: "Implementation",
          description: "Number of invalid IDs displayed",
          submittedCode: (() => {
              const initLine = this.extractQ2Code(code, ['= 0']) || '';
              if (!initLine) return '';

              const varName = initLine.split('=')[0].trim();

              return this.extractQ2Code(code, [
                  `print(${varName}`,
                  `print(f"`,
                  `print("`,
              ].filter(pattern =>
                  code.includes(pattern) &&
                  code.includes(varName)
              )) || '';
          })(),
          evaluationNotes: "Check if total invalid count is displayed"
      }
  ];

  return {
      studentSubmission: {
          tasks
      }
  };
}
static formatSubmission(questionId, code) {
    switch (questionId) {
        case "q1":
            return this.formatQ1Submission(code);
        case "q2":
            return this.formatQ2Submission(code);
        case "q3":
            return this.formatQ3Submission(code);
        default:
            throw new Error(`No submission formatter found for question ${questionId}`);
    }
}

}

export default SubmissionFormatService;

