import React, { useState, useEffect } from "react";
import AceEditor from "react-ace";

// Import necessary Ace editor modules
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/python";
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/snippets/python';
import ace from 'ace-builds';



const CodeEditor = ({ initialCode, onCodeChange }) => {
  const [code, setCode] = useState(initialCode);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    setCode(initialCode);
    setIsEdited(false);
  }, [initialCode]);

  useEffect(() => {
    // Define the custom completer
    const customCompleter = {
      getCompletions: (editor, session, pos, prefix, callback) => {
        const completions = [
          { caption: 'print', value: 'print()', meta: 'function' },
          { caption: 'input', value: 'input()', meta: 'function' },
        ];
        callback(null, completions);
      },
    };

    // Add the custom completer to Ace
    ace.require('ace/ext/language_tools').addCompleter(customCompleter);
  }, []); // Run only once on mount

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    const hasChanged = newCode !== initialCode;
    setIsEdited(hasChanged);
    onCodeChange(newCode, hasChanged);
  };

  return (
    <AceEditor
      mode="python"
      theme="monokai"
      onChange={handleCodeChange}
      value={code}
      name="code-editor"
      editorProps={{ $blockScrolling: true }}
      style={{ width: "100%", height: "600px" }}

      style={{ width: '100%', height: '600px' }}
      fontSize={16}
      readOnly={false}
    />
  );
};

export default CodeEditor;
