import { useEffect, useState } from "react";
import QuestionCard from "../components/QuestionCard";
import { questions } from "../data/questions";
import { Mail, AlertCircle,Info } from 'lucide-react';


export default function Home() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState('');
  const [validateEmailButton, setValidateEmail] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    // Validate email as user types
    if (inputEmail && !validateEmail(inputEmail)) {
      setEmailError('Please enter a valid email address');
      setValidateEmail(true)
    } else {
      setEmailError('');
      setValidateEmail(false)

    }
  };
  return (
    <div className="min-h-screen p-8 pb-20 sm:p-10">
      <main className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Edexcel IGCSE 4CP0/02 Exam Practice & Assessment Platform by <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
              Teepee.ai
            </a></h1>
              {/* Email Input */}
              <div className="bg-white shadow-lg rounded-xl p-6 mb-6">
          <div className="mb-4">
            <label 
              htmlFor="email" 
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Email Address <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Mail 
                  className={`h-5 w-5 ${
                    emailError 
                      ? 'text-red-500' 
                      : 'text-gray-400'
                  }`} 
                />
              </div>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                className={`
                  w-full 
                  pl-10 
                  pr-3 
                  py-2 
                  border-2 
                  rounded-lg 
                  transition-all 
                  duration-300
                  ${
                    emailError 
                      ? 'border-red-500 focus:ring-2 focus:ring-red-200' 
                      : 'border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200'
                  }
                `}
                placeholder="you@example.com"
                required
              />
            </div>
            {emailError && (
              <div className="mt-2 flex items-center text-sm text-red-600 animate-bounce">
                <AlertCircle className="h-4 w-4 mr-2" />
                {emailError}
              </div>
            )}




          <div className="bg-blue-50 border-l-4 border-blue-500 p-3 mt-4 flex items-center">
          <Info className="h-5 w-5 text-blue-500 mr-3" />
          <p className="text-sm text-blue-700">
          Provide your email address to receive customized AI feedback on your answer.</p>
        </div>
          </div>
      </div>
        
        {questions.map((question) => (
          <QuestionCard key={question.id} question={question} email={validateEmailButton} emailText={email} />
        ))}
      </main>
    </div>
  );
}
